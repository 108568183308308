import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import '@fontsource/roboto'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import { Requests } from '@/requests'

Vue.config.productionTip = false
Vue.use(PiniaVuePlugin)
Vue.use(VueToast)

export const pinia = createPinia()

new Vue({
  router,
  pinia,
  render: h => h(App),
  async created () {
    await this.getDynamicRoutes()
  },
  methods: {
    getDynamicRoutes () {
      Requests.get('/api/quality-rate-rates')
        .then((response) => {
          [...response.data.data].forEach((obj) => {
            this.createAndAppendRoute(obj)
          })
        })
        .catch(() => { })
    },
    createAndAppendRoute: route => {
      if (route?.alias === null) {
        route.alias = ''
      }

      router.addRoute(
        {
          path: `/${route.alias}`,
          component: () => import('@/views/HomeView'),
          name: `home${route?.alias}`,
          props: { rate: `${route?.alias}/checklist`, nameRate: route.name }
        }
      )
      router.addRoute(
        {
          path: route.alias === '' ? '/checklist' : `/${route.alias}/checklist`,
          component: () => import('@/views/CheckList'),
          name: `checklist${route?.alias}`,
          props: { rateId: route?.id, nameRate: route.name, fromType: route.type }
        }
      )
    }
  }
}).$mount('#app')
