<template>
  <div><h1>Страница не найдена</h1></div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>
